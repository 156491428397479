import React, { useState } from 'react'
import Header from '~components/organisms/Header'
import Footer from '~components/organisms/Footer'
import MainMenu from '~components/organisms/MainMenu'

import IconButton from '@material-ui/core/IconButton'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PageProgress from 'shared-ui/components/PageProgress'
import Icon from '@material-ui/core/Icon'
import { AccountProvider } from '~src/AccountContext'
import { AppFrameProvider, withAppFrame } from 'shared-ui/components/AppFrameContext'
import { useApi } from 'shared-ui/api/ApiContext'
import { isPrintMode, useMember, usePageData } from 'shared-ui/components/pages/PageWrapper'
import Chatbot from 'shared-ui/components/molecules/Chatbot'
import WebsocketEvents from '~components/WebsocketEvents'
import PageRetention from '~components/PageRetention'
import GoogleServices from '~components/GoogleServices'
import ReCaptcha from 'shared-ui/components/ReCaptcha'
import IntlStyleFixes from '~src/IntlStyleFixes'
import clsx from 'clsx'

function AppFrame(props) {
  const api = useApi()
  const { appMode, componentName, appFrame = {} } = usePageData()
  const member = useMember()

  const handleAccountChange = selectedAccountId => {
    api.changeSelectedAccount({ selectedAccountId })
  }

  const isPrint = isPrintMode()
  const { children, busy, routeKey, contentKey } = props
  const { accounts, selectedAccountId } = member || {}

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  let xsMenuItems = []
  const { header: { items = [] } = {} } = appFrame
  items.forEach(item => {
    if (!item.props.xsIconVisible) {
      xsMenuItems.push({ ...item.props, _link: item._link })
    }
  })

  const {
    header,
    menu,
    apps,
    footer,
    settings: { languages, general, technical: { captchaVisibility } = {} } = {},
  } = appFrame
  const { displayHealthWarning, healthWarningFooter } = general || {}

  return (
    <AppFrameProvider value={appFrame}>
      <AccountProvider
        value={{ accounts, selectedAccountId, onAccountChange: handleAccountChange }}
      >
        <Chatbot />
        <GoogleServices />
        <ReCaptcha />
        <IntlStyleFixes />

        <WebsocketEvents>
          <PageRetention />
          <div
            className={clsx(
              'bg-paper relative z-0 flex min-h-screen w-full flex-col flex-wrap overflow-auto print:block',
              { 'pb-[20vh]': displayHealthWarning && healthWarningFooter },
            )}
            data-template-id={componentName}
          >
            <PageProgress visible={busy} className="!absolute z-[9999] w-full" />

            {!isPrint && header && (
              <Header
                id="header"
                member={member}
                languages={languages}
                data={header}
                mode={appMode}
                renderDrawerIcon={() => (
                  <div className="z-1 flex h-full w-16 items-center justify-center" align="center">
                    {!!menu && (
                      <IconButton
                        aria-label="Open drawer"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                      >
                        <Icon>menu</Icon>
                      </IconButton>
                    )}
                  </div>
                )}
              />
            )}

            <TransitionGroup className="print:bg-paper max-h-full w-full grow">
              <CSSTransition
                key={routeKey}
                timeout={{ enter: 450, exit: 0 }}
                style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                classNames={{
                  enter: 'opacity-0',
                  enterActive: 'opacity-100 transition-opacity ease-out duration-200 delay-100',
                }}
              >
                <div key={contentKey}>{children}</div>
              </CSSTransition>
            </TransitionGroup>

            {!isPrint && footer && (
              <Footer
                className="w-full"
                data={footer}
                captchaBrandingVisibility={captchaVisibility}
              />
            )}

            {!isPrint && menu && (
              <div className="h-full">
                <MainMenu
                  open={isMenuOpen}
                  data={menu}
                  apps={apps}
                  member={member}
                  xsMenuItems={xsMenuItems}
                  className="h-full"
                  onOverlayClick={() => setIsMenuOpen(false)}
                  api={api}
                />
              </div>
            )}
          </div>
        </WebsocketEvents>
      </AccountProvider>
    </AppFrameProvider>
  )
}

export default AppFrame

export { withAppFrame }
