module.exports = {
  DOCUMENT_TYPE_SIZE: 'DOCUMENT_TYPE_SIZE',
  CONTRACTS: 'CONTRACTS',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  GIVE_FEEDBACK: 'GIVE_FEEDBACK',
  CURRENCY_FULL_NAME: 'CURRENCY_FULL_NAME',
  CURRENCY_SHORT_NAME: 'CURRENCY_SHORT_NAME',
  COMING_SOON: 'COMING_SOON',
  SOLD_OUT: 'SOLD_OUT',
  FILTER_ALL: 'FILTER_ALL',
  RESET_FILTERS: 'RESET_FILTERS',
  ALL: 'ALL',
  AMOUNT: 'AMOUNT',
  GREETING: 'GREETING',
  J_POINTS: 'J_POINTS',
  J_POINTS_HISTORY: 'J_POINTS_HISTORY',
  DATE: 'DATE',
  ORDER: 'ORDER',
  ORDER_NOW: 'ORDER_NOW',
  PLACE_ORDER: 'PLACE_ORDER',
  ORDERED: 'ORDERED',
  PRODUCT: 'PRODUCT',
  SEARCH: 'SEARCH',
  RESULT: 'RESULT',
  NEW: 'NEW',
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  CANCEL: 'CANCEL',
  RESET: 'RESET',
  CONFIRM: 'CONFIRM',
  SUBMIT: 'SUBMIT',
  SAVE: 'SAVE',
  SEND: 'SEND',
  YES: 'YES',
  NO: 'NO',
  NEXT: 'NEXT',
  PREVIOUS: 'PREVIOUS',
  START: 'START',
  END: 'END',
  PERIOD: 'PERIOD',
  TOTAL: 'TOTAL',
  NO_ITEMS: 'NO_ITEMS',
  NAME: 'NAME',
  STATUS: 'STATUS',
  LOG_OUT: 'LOG_OUT',
  PRICE: 'PRICE',
  /* labels below are up for clean up */
  ACTION: 'ACTION',
  REWARD: 'REWARD',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  IN_PROGRESS: 'IN_PROGRESS',
  CODE: 'CODE',
  MY_BALANCE: 'MY_BALANCE',
  BALANCE_DATE: 'BALANCE_DATE',
  BALANCE_TIME: 'BALANCE_TIME',
  BALANCE_ACTION: 'BALANCE_ACTION',
  BALANCE: 'BALANCE',
  NUM_JPOINTS_EXPIRING_THIS_MONTH: 'NUM_JPOINTS_EXPIRING_THIS_MONTH',
  ACHIEVEMENT: 'ACHIEVEMENT',
  SCANNED_PRODUCT: 'SCANNED_PRODUCT',
  COMPLIANCE: 'COMPLIANCE',
  DEFAULT_OUT_OF_STOCK: 'DEFAULT_OUT_OF_STOCK',
  DEFAULT_NOT_ENOUGH_IN_STOCK: 'DEFAULT_NOT_ENOUGH_IN_STOCK',
  DEFAULT_NOT_ENOUGH_JPOINTS: 'DEFAULT_NOT_ENOUGH_JPOINTS',
  DEFAULT_AGREEMENT_REQUIRED: 'DEFAULT_AGREEMENT_REQUIRED',
  NEWS_COPYRIGHT: 'NEWS_COPYRIGHT',
  FILTER_PRODUCTS: 'FILTER_PRODUCTS',
  FILTER_REWARDS: 'FILTER_REWARDS',
  REWARDS_HISTORY: 'REWARDS_HISTORY',
  FILTER_SALES: 'FILTER_SALES',
  ALL_PRODUCTS_OF: 'ALL_PRODUCTS_OF',
  LEARN_MORE_ABOUT: 'LEARN_MORE_ABOUT',
  TECHNICAL_DATA: 'TECHNICAL_DATA',
  NUMBERS_AND_FACTS: 'NUMBERS_AND_FACTS',
  ORDER_STATUS: 'ORDER_STATUS',
  SUBMITTED: 'SUBMITTED',
  ORDER_STATUS_IN_PROGRESS: 'ORDER_STATUS_IN_PROGRESS',
  ORDER_STATUS_SHIPPED: 'ORDER_STATUS_SHIPPED', // dispatched
  ORDER_STATUS_FAILED: 'ORDER_STATUS_FAILED',
  ORDER_STATUS_PENDING: 'ORDER_STATUS_PENDING',
  ORDER_STATUS_DELIVERED: 'ORDER_STATUS_DELIVERED',
  ORDER_STATUS_PARTIALLY_DELIVERED: 'ORDER_STATUS_PARTIALLY_DELIVERED',
  ORDER_STATUS_INVOICED: 'ORDER_STATUS_INVOICED',
  ORDER_STATUS_COLLECTED: 'ORDER_STATUS_COLLECTED',
  ORDER_STATUS_CANCELLED: 'ORDER_STATUS_CANCELLED',
  ORDER_STATUS_GENERIC1: 'ORDER_STATUS_GENERIC1',
  ORDER_STATUS_GENERIC2: 'ORDER_STATUS_GENERIC2',
  CANCEL_ORDER: 'CANCEL_ORDER',
  RE_ORDER: 'RE_ORDER',
  DOWNLOAD_INVOICE: 'DOWNLOAD_INVOICE',
  PRODUCTS: 'PRODUCTS',
  ADD_NEW_PRODUCT: 'ADD_NEW_PRODUCT',
  NR_OF_CARTONS: 'NR_OF_CARTONS',
  NR_OF_UNITS: 'NR_OF_UNITS',
  CARTONS: 'CARTONS',
  UNIT: 'UNIT',
  UNITS: 'UNITS',
  RECOMMENDED_RETAIL_PRICE: 'RECOMMENDED_RETAIL_PRICE',
  RECOMMENDED_QUANTITY: 'RECOMMENDED_QUANTITY',
  RECOMMENDED: 'RECOMMENDED',
  TRAY: 'TRAY',
  FACING: 'FACING',
  PACKAGE_CONVERSION: 'PACKAGE_CONVERSION',
  ORDER_AMOUNT: 'ORDER_AMOUNT',
  ORDER_NR: 'ORDER_NR',
  CONFIRM_YOUR_ORDER: 'CONFIRM_YOUR_ORDER',
  PREVIOUS_ORDER: 'PREVIOUS_ORDER',
  PREVIOUS_VALUES: 'PREVIOUS_VALUES',
  ORDER_HISTORY: 'ORDER_HISTORY',
  QR_SCANNER: 'QR_SCANNER',
  UPLOAD_CHALLENGE_HISTORY: 'UPLOAD_CHALLENGE_HISTORY',
  CARTON: 'CARTON',
  PACK: 'PACK',
  OFFER: 'OFFER',
  MARKET_SHARE: 'MARKET_SHARE',
  MARKET_SHARE_COMPARISON: 'MARKET_SHARE_COMPARISON',
  INFORMATION: 'INFORMATION',
  RULES_AND_CONDITIONS: 'RULES_AND_CONDITIONS',
  SALES_PERFORMANCE: 'SALES_PERFORMANCE',
  SALES_REP_VISITS: 'SALES_REP_VISITS',
  CONSUMER_PROGRAM: 'CONSUMER_PROGRAM',
  FOOTER_COPYRIGHT: 'FOOTER_COPYRIGHT',
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  ORDER_DATE: 'ORDER_DATE',
  DELETED_CONTRACTS: 'DELETED_CONTRACTS',
  SUGGESTION: 'SUGGESTION',
  SINGLE_SIGN_ON: 'SINGLE_SIGN_ON',
  NO_PRODUCTS_AVAILABLE: 'NO_PRODUCTS_AVAILABLE',
  NO_PRODUCTS_SELECTED: 'NO_PRODUCTS_SELECTED',
  ALL_PRODUCTS_SELECTED: 'ALL_PRODUCTS_SELECTED',
  FEEDBACK: 'FEEDBACK',
  NO_AGREEMENTS_ITEMS: 'NO_AGREEMENTS_ITEMS',
  DATA_CARD_HISTORY: 'DATA_CARD_HISTORY',
  SHOPPING_CART: 'SHOPPING_CART',
  ADD_RECOMMENDED_ORDER: 'ADD_RECOMMENDED_ORDER',
  RESET_PRODUCT_QUANTITIES: 'RESET_PRODUCT_QUANTITIES',
  DATA_CARD_INFO: 'DATA_CARD_INFO',
  ORDER_DUE_DATE: 'ORDER_DUE_DATE',
  NO_CONTENT: 'NO_CONTENT',
  SHOW_IMAGE: 'SHOW_IMAGE',
  UP_TO_DATE: 'UP_TO_DATE',
  ITEM_IN_PACKAGE: 'ITEM_IN_PACKAGE',
  APPLY_FILTERS: 'APPLY_FILTERS',
  TARGETED: 'TARGETED',
  ADD_TO_CART: 'ADD_TO_CART',
  VAT: 'VAT',
  INCLUDE: 'INCLUDE',
  NOT_APPLICABLE_SHORT: 'NOT_APPLICABLE_SHORT',
  PACKTRACK: 'PACKTRACK',
  ORDER_DAY: 'ORDER_DAY',
  DELIVERY_DAY: 'DELIVERY_DAY',
  POINTS: 'POINTS',
  REJECTION_REASON: 'REJECTION_REASON',
  QUANTITY: 'QUANTITY',
  UNAVAILABLE_PRODUCTS: 'UNAVAILABLE_PRODUCTS',
  READY_TO_ORDER_PRODUCTS: 'READY_TO_ORDER_PRODUCTS',
  NOTE_ON_AVAILABILITY: 'NOTE_ON_AVAILABILITY',
  INSTOCK: 'INSTOCK',
  BACKORDER: 'BACKORDER',
  DISCONTINUED: 'DISCONTINUED',
  INSTOREONLY: 'INSTOREONLY',
  OUTOFSTOCK: 'OUTOFSTOCK',
  SOLDOUT: 'SOLDOUT',
  APPLY: 'APPLY',
  TOTAL_PRICE: 'TOTAL_PRICE',
  Quantity: 'Quantity',
  PAYMENT_STATUS: 'PAYMENT_STATUS',
  AMOUNT_DUE: 'AMOUNT_DUE',
  ACCOUNT_BALANCE: 'ACCOUNT_BALANCE',
  ACCOUNT_BALANCE_RECENT_TRANSACTIONS: 'ACCOUNT_BALANCE_RECENT_TRANSACTIONS',
  NO_DATA_AVAILABLE_YET: 'NO_DATA_AVAILABLE_YET',

  REWARD_TYPE_ORDER: 'REWARD_TYPE_ORDER',
  REWARD_TYPE_CONVERT: 'REWARD_TYPE_CONVERT',
  REWARD_TYPE_RAFFLE: 'REWARD_TYPE_RAFFLE',

  REWARD_CURRENCY_NO_LIMIT: 'REWARD_CURRENCY_NO_LIMIT',
  REWARD_TRANSACTIONS: 'REWARD_TRANSACTIONS',
  REWARD_POINTS_SPENT: 'REWARD_POINTS_SPENT',
  REWARD_OUT_OF: 'REWARD_OUT_OF',
  REWARD_NO_LIMIT: 'REWARD_NO_LIMIT',
  REWARD_POINTS_EXPIRING_DATE: 'REWARD_POINTS_EXPIRING_DATE',
  REWARD_POINTS_LIMITATION_TITLE: 'REWARD_POINTS_LIMITATION_TITLE',
  REWARD_LIMITATION_DATE: 'REWARD_LIMITATION_DATE',
  REWARD_LIMITATION_ERROR: 'REWARD_LIMITATION_ERROR',
  REWARD_NO_EXPIRATION_TITLE: 'REWARD_NO_EXPIRATION_TITLE',

  RAFFLE_PARTICIPATE: 'RAFFLE_PARTICIPATE',
  RAFFLE_STOCK_TITLE_ACTIVE: 'RAFFLE_STOCK_TITLE_ACTIVE',
  RAFFLE_STOCK_INFO_ACTIVE: 'RAFFLE_STOCK_INFO_ACTIVE',
  RAFFLE_STOCK_INFO_ACTIVE_ONLY_ONE: 'RAFFLE_STOCK_INFO_ACTIVE_ONLY_ONE',
  RAFFLE_STOCK_INFO_ENDED: 'RAFFLE_STOCK_INFO_ENDED',
  RAFFLE_STOCK_INFO_NOT_STARTED: 'RAFFLE_STOCK_INFO_NOT_STARTED',
  RAFFLE_INFO_OUT_OF_STOCK: 'RAFFLE_INFO_OUT_OF_STOCK',
  RAFFLE_INFO_ENDED: 'RAFFLE_INFO_ENDED',
  RAFFLE_INFO_LIMIT_EXCEEDED: 'RAFFLE_INFO_LIMIT_EXCEEDED',
  RAFFLE_STATUS_LIMIT_EXCEEDED: 'RAFFLE_STATUS_LIMIT_EXCEEDED',
  RAFFLE_STATUS_NOT_STARTED: 'RAFFLE_STATUS_NOT_STARTED',
  RAFFLE_STATUS_ACTIVE: 'RAFFLE_STATUS_ACTIVE',
  RAFFLE_STATUS_ENDED: 'RAFFLE_STATUS_ENDED',
  RAFFLE_STATUS_ENDS_SOON: 'RAFFLE_STATUS_ENDS_SOON',
  RAFFLE_WON: 'RAFFLE_WON',
  RAFFLE_LOST: 'RAFFLE_LOST',

  LAST_QUARTER: 'LAST_QUARTER',
  CURRENT_QUARTER: 'CURRENT_QUARTER',
  VOLUME: 'VOLUME',

  MY_BALANCE_WIDGET_ACTION: 'MY_BALANCE_WIDGET_ACTION',
  MY_BALANCE_WIDGET_POINTS: 'MY_BALANCE_WIDGET_POINTS',

  CODE_ON_PACK_INPUT: 'INPUT_COP',
  SUBMIT_CODE: 'SUBMIT_CODE',

  CALL: 'CALL',
  ADD_TO_FAVORITES: 'ADD_TO_FAVORITES',
  REMOVE_FROM_FAVORITES: 'REMOVE_FROM_FAVORITES',
  DIRECTIONS: 'DIRECTIONS',
  FILTER: 'FILTER',
  SHOW_WHOLESALERS: 'SHOW_WHOLESALERS',
  NEAR_MY_STORE: 'NEAR_MY_STORE',
  NEAR_MY_LOCATION: 'NEAR_MY_LOCATION',
}
