const createAsyncComponent = importer => ({
  import: () => importer(),
})

const componentByName = {
  Dashboard: createAsyncComponent(() => import('./components/pages/Dashboard')),

  LoginPageEmailaddress: createAsyncComponent(
    () => import('./components/pages/LoginPage/LoginPage'),
  ),
  LoginPageMobilephone: createAsyncComponent(
    () => import('./components/pages/LoginPage/LoginPage'),
  ),

  MaintenancePage: createAsyncComponent(() => import('./components/pages/MaintenancePage')),

  LoginProblemsPageEmailaddress: createAsyncComponent(
    () => import('shared-ui/components/pages/ResetPasswordPage'),
  ),
  LoginProblemsPageMobilephone: createAsyncComponent(
    () => import('./components/pages/LoginProblemsPageMobilephone'),
  ),

  LoginProblemsSetPassword: createAsyncComponent(
    () => import('./components/pages/LoginSetPasswordPage'),
  ),
  RegistrationSuccessPage: createAsyncComponent(
    () => import('./components/pages/RegistrationSuccessPage'),
  ),
  RegistrationByInvitePage: createAsyncComponent(
    () => import('./components/pages/RegistrationByInvitePage'),
  ),
  SignupPageMobilephone: createAsyncComponent(
    () => import('./components/pages/SignupPageMobilephone'),
  ),
  MiscPageTemplate: createAsyncComponent(
    () => import('./components/templates/ModularPageTemplate'),
  ),
  MiscOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/MiscOverviewTemplate'),
  ),
  WorldOfTobaccoDetailTemplate: createAsyncComponent(
    () => import('./components/templates/WorldOfTobaccoDetailTemplate'),
  ),
  BrandOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/BrandOverviewTemplate'),
  ),
  BrandDetailTemplate: createAsyncComponent(
    () => import('./components/templates/BrandDetailTemplate'),
  ),
  NewsOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/NewsOverviewTemplate'),
  ),
  ProfilePageTemplate: createAsyncComponent(
    () => import('./components/templates/ProfilePageTemplate'),
  ),
  ContactPageTemplate: createAsyncComponent(
    () => import('./components/templates/ContactPageTemplate'),
  ),
  ProductOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/ProductOverviewTemplate'),
  ),
  ProductDetailTemplate: createAsyncComponent(
    () => import('./components/templates/ProductDetailTemplate'),
  ),
  RewardOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/RewardOverviewTemplate'),
  ),
  RewardDetailTemplate: createAsyncComponent(
    () => import('./components/templates/RewardDetailTemplate'),
  ),
  MyLoyaltyOverviewTemplate: createAsyncComponent(
    () => import('./templates/MyLoyaltyOverviewTemplate'),
  ),
  SalesIndexOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/SalesIndexOverviewTemplate'),
  ),
  ExpandablesOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/ExpandablesOverviewTemplate'),
  ),
  ConsumerProgramsOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/ConsumerProgramsOverviewTemplate'),
  ),
  ConsumerTrialsOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/ConsumerTrialsOverviewTemplate'),
  ),
  ConsumerProgramDetailTemplate: createAsyncComponent(
    () => import('./components/templates/ModularPageTemplate'),
  ),
  OfflineProgramsOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/OfflineProgramsOverviewTemplate'),
  ),
  OfflineProgramDetailsTemplate: createAsyncComponent(
    () => import('./components/templates/OfflineProgramDetailsTemplate'),
  ),
  PackTrackDetailTemplate: createAsyncComponent(
    () => import('./components/templates/PackTrackDetailTemplate'),
  ),
  PackTrackPageTemplate: createAsyncComponent(
    () => import('./components/templates/PackTrackPageTemplate'),
  ),
  PlanogramOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/PlanogramOverviewTemplate'),
  ),
  SpecialOffersOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/SpecialOffersOverviewTemplate'),
  ),
  SpecialOfferDetailTemplate: createAsyncComponent(
    () => import('./components/templates/SpecialOfferDetailTemplate'),
  ),
  EduActionOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/EduActionOverviewTemplate'),
  ),
  UniversityOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/UniversityOverviewTemplate'),
  ),
  UniversityDetailTemplate: createAsyncComponent(
    () => import('~components/templates/UniversityDetailTemplate/UniversityDetailTemplate'),
  ),

  UploadChallengeHistoryPopupTemplate: createAsyncComponent(
    () => import('./components/templates/UploadChallengeHistoryPopupTemplate'),
  ),
  GenericMessagePage: createAsyncComponent(
    () => import('shared-ui/components/templates/GenericMessagePage'),
  ),

  OrdersTemplate: createAsyncComponent(() => import('./components/templates/OrdersTemplate')),
  ShoppingCartTemplate: createAsyncComponent(
    () => import('./components/templates/ShoppingCartTemplate'),
  ),

  MessagePopupTemplate: createAsyncComponent(
    () => import('shared-ui/components/templates/GenericMessagePopupTemplate'),
  ),
  MyBalancePopupTemplate: createAsyncComponent(
    () => import('./components/templates/MyBalancePopupTemplate'),
  ),
  MyRankPopupTemplate: createAsyncComponent(
    () => import('./components/templates/MyRankPopupTemplate'),
  ),
  MyTierPopupTemplate: createAsyncComponent(
    () => import('./components/templates/MyTierPopupTemplate'),
  ),
  ContactPopupTemplate: createAsyncComponent(
    () => import('./components/templates/ContactPopupTemplate'),
  ),
  FeedbackPopupTemplate: createAsyncComponent(
    () => import('./components/templates/ContactPopupTemplate'),
  ),

  OrderHistoryPopupTemplate: createAsyncComponent(
    () => import('./components/templates/OrderHistoryPopupTemplate'),
  ),
  AgreementRewardsProgressPopupTemplate: createAsyncComponent(
    () => import('./components/templates/AgreementRewardsProgressPopupTemplate'),
  ),
  MyAgreementsRewardsProgressPopupTemplate: createAsyncComponent(
    () => import('./components/templates/MyAgreementsRewardsProgressPopupTemplate'),
  ),
  UserFilesPopupTemplate: createAsyncComponent(
    () => import('./components/templates/UserFilesPopupTemplate'),
  ),
  VerifyPopupTemplate: createAsyncComponent(
    () => import('./components/templates/VerifyPopupTemplate'),
  ),

  QuizsPopupTemplate: createAsyncComponent(
    () => import('./components/templates/QuizzesPopupTemplate'),
  ),
  ChallengesPopupTemplate: createAsyncComponent(
    () => import('./components/templates/ChallengesPopupTemplate'),
  ),

  UniversityModulesPopupTemplate: createAsyncComponent(
    () => import('./components/templates/UniversityModulesPopupTemplate'),
  ),

  NotificationCenterPopupTemplate: createAsyncComponent(
    () => import('./components/templates/NotificationCenterPopupTemplate'),
  ),
  GamesPopupTemplate: createAsyncComponent(
    () => import('./components/templates/GamesPopupTemplate'),
  ),
  SurveysPopupTemplate: createAsyncComponent(
    () => import('./components/templates/SurveysPopupTemplate'),
  ),
  PinsPopupTemplate: createAsyncComponent(() => import('./components/templates/PinsPopupTemplate')),
  CmsPushNotificationsPopupTemplate: createAsyncComponent(
    () => import('./components/templates/NotificationsPopupTemplate'),
  ),
  RewardsHistoryPopupTemplate: createAsyncComponent(
    () => import('./components/templates/RewardsHistoryPopupTemplate'),
  ),
  ConsumerSurveysPopupTemplate: createAsyncComponent(
    () => import('./components/templates/ConsumerSurveysPopupTemplate'),
  ),
  ConsumerDataCollectionOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/ConsumerDataCollectionOverviewTemplate'),
  ),
  ConsumerDataHistoryPopupTemplate: createAsyncComponent(
    () => import('./components/templates/ConsumerDataHistoryPopupTemplate'),
  ),
  TrialsPopupTemplate: createAsyncComponent(
    () => import('./components/templates/ConsumerTrialsPopupTemplate'),
  ),

  B2XsPopupTemplate: createAsyncComponent(() => import('./components/templates/B2XsPopupTemplate')),

  ScanningConditionPopupTemplate: createAsyncComponent(
    () => import('./components/templates/ScanningConditionPopupTemplate'),
  ),

  DigitalSignaturePopupTemplate: createAsyncComponent(
    () => import('./components/templates/MyAgreementsDigitalSignaturePopupTemplate'),
  ),

  MyAgreementsOverviewTemplate: createAsyncComponent(
    () => import('./components/templates/MyAgreementsOverviewTemplate'),
  ),

  MyAgreementsDetailTemplate: createAsyncComponent(
    () => import('~components/templates/MyAgreementsDetailTemplate'),
  ),

  AccountBalanceOverviewPageTemplate: createAsyncComponent(
    () => import('~components/templates/AccountBalanceOverviewPageTemplate'),
  ),

  /* Canary Island */
  // ----------- reason -> JTITOPGT-4890 4532 RITM2068772 Canary Island Localization removal: CartonPrice
  // IcProductDetailTemplate: createAsyncComponent(() =>
  //   import('./ic/components/templates/ProductDetailTemplate'),
  // ),
  // IcProductOverviewTemplate: createAsyncComponent(() =>
  //   import('./ic/components/templates/ProductOverviewTemplate'),
  // ),
  IcSpecialOfferDetailTemplate: createAsyncComponent(
    () => import('~components/localised/ic/components/templates/SpecialOfferDetailTemplate'),
  ),

  /* Benelux */
  PrepaidCardPage: createAsyncComponent(
    () => import('~components/localised/be/components/PrepaidCardPage'),
  ),
  CollaborationPage: createAsyncComponent(
    () => import('~components/localised/be/components/CollaborationPage'),
  ),
  PartnerPlanOverviewPage: createAsyncComponent(
    () => import('~components/localised/be/components/PartnerPlanOverviewPage'),
  ),
  PartnerPlanDetailPage: createAsyncComponent(
    () => import('~components/localised/be/components/PartnerPlanDetailPage'),
  ),
  CmsDataUsagePopupTemplate: createAsyncComponent(
    () => import('~components/localised/be/components/DataUsagePopupTemplate'),
  ),

  /* Poland */
  PlMyProgramsOverviewTemplate: createAsyncComponent(
    () => import('~components/localised/pl/components/MyProgramsOverviewTemplate'),
  ),
  PlMyProgramsDetailTemplate: createAsyncComponent(
    () => import('~components/localised/pl/components/MyProgramsDetailTemplate'),
  ),

  /* Singapore */
  SgMyProgramsOverviewTemplate: createAsyncComponent(
    () => import('~components/localised/sg/components/MyProgramsOverviewTemplate'),
  ),
  SgMyProgramsDetailTemplate: createAsyncComponent(
    () => import('~components/localised/sg/components/MyProgramsDetailTemplate'),
  ),

  /* United States*/
  PaymentsIndexOverviewTemplate: createAsyncComponent(
    () => import('~components/localised/us/components/templates/PaymentsIndexOverviewTemplate'),
  ),
  ContractsIndexOverviewTemplate: createAsyncComponent(
    () => import('~components/localised/us/components/templates/ContractsIndexOverviewTemplate'),
  ),
  USProfilePageTemplate: createAsyncComponent(
    () => import('~components/localised/us/components/templates/ProfilePageTemplate'),
  ),

  /* Kazakhstan */
  KzMyProgramsOverviewTemplate: createAsyncComponent(
    () => import('~components/localised/kz/components/MyProgramsOverviewTemplate'),
  ),
  KzMyProgramsDetailTemplate: createAsyncComponent(
    () => import('~components/localised/kz/components/MyProgramsDetailTemplate'),
  ),
  KzAgreementsPopupTemplate: createAsyncComponent(
    () => import('~components/localised/kz/components/AgreementsPopupTemplate'),
  ),
  ContractSigningPopupTemplate: createAsyncComponent(
    () => import('~components/localised/kz/components/ContractSigningPopupTemplate'),
  ),

  WholesalersNearbyTemplate: createAsyncComponent(
    () => import('~components/templates/WholesalersNearbyTemplate'),
  ),
}

export default function componentLoaderByName(name) {
  return componentByName[name]
}
