import React, { useEffect, useRef } from 'react'
import AnalyticsEvents from 'shared-ui/utils/AnalyticsEvents'

const TrackedVideo = React.forwardRef((props, ref) => {
  const innerRef = useRef()

  useEffect(() => {
    return () => {
      const videoRef = innerRef.current
      let { currentTime, duration } = videoRef
      const { name = '' } = props

      if (!duration) duration = 100

      const currentTimeInPercentage = Math.trunc((currentTime / duration) * 100).toString() + '%'
      const videoName = name.split('/').pop()

      AnalyticsEvents.pushVideoCompletionRate(videoName, currentTimeInPercentage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <video
      ref={domNode => {
        innerRef.current = domNode
        if (!ref) return
        if (typeof ref === 'function') {
          return ref(domNode)
        }
        ref.current = domNode
      }}
      {...props}
    />
  )
})

export default TrackedVideo
